.icon-container {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.icon-container:hover {
    background-color: rgba(240, 240, 240, 0.5); /* Change this to the color you want on hover with 50% opacity */
}

.icon-container svg {
    transition: color 0.3s ease;
}

/* Add this block to increase the size of the icon on hover */
.icon-container:hover svg {
    transform: scale(1.2); /* Adjust the scale factor as needed */
}

.linkedin-icon:hover svg {
    color: #0077B5;
}

.github-icon:hover svg {
    color: #000000;
}

.instagram-icon:hover svg {
    color: #d62976;
}

.twitter-icon:hover svg {
    color: #000000;
}

.mail-icon:hover svg {
    color: #DB4437;
}

.leetcode-icon:hover svg {
    color: #dba21a;
}
.icon-container a {
    color: inherit;
    text-decoration: none;
}
