.buttonStyle {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 300;
    font-size: 0.25rem;
    color: #3d424a;
    background-color: rgba(255, 255, 255, 0.5); /* white background with 50% opacity */
    font-size: 20px; 
    border: none; 
    height : 40px;
    width: 113.66px;
    text-align: center; 
    text-decoration: none; 
    display: inline-block;
    margin: 10px 10px; /* Increase the margin as per your requirement */
    cursor: pointer;
    border-radius: 12px; 
    border: 0.5px solid #cacbcd;
    transition: transform 0.3s; /* Add transition for smooth effect */
  }
  
  .buttonStyle:hover {
    background-color: #e2e8f0;
    transform: translateY(-3px); /* Lift the button up by 3 pixels on hover */
  }