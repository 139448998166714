* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
}

.particles-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(128, 128, 128, 0.9);
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  flex-direction: column;

}

.modal-container {
  padding: 10px 10px 10px 10px;
}

.buttonStyle {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  font-size: 0.25rem;
  color: #3d424a;
  background-color: rgba(255, 255, 255, 0.5);
  /* white background with 50% opacity */
  font-size: 20px;
  border: none;
  height: 40px;
  width: 113.66px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 10px 10px;
  /* Increase the margin as per your requirement */
  cursor: pointer;
  border-radius: 12px;
  border: 0.5px solid #cacbcd;
  transition: transform 0.3s;
  /* Add transition for smooth effect */
}

.buttonStyle:hover {
  background-color: #e2e8f0;
  transform: translateY(-3px);
  /* Lift the button up by 3 pixels on hover */
}

.navbar-list li:hover {
  background-color: #e2e8f0;
}

.model-pack {
  border: 1px solid #e6e6e7;
  backdrop-filter: blur(5px);
  background-color: rgba(245, 245, 245, 0.5);
  border-radius: 15px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  width: 50vw;
  margin-bottom: 10px;
  padding:10px
}

.model-pack:hover {

  transform: scale(1.05);
}
.modal-image {
  width: 70px;
  height: 70px;
  border-radius: 5px;
}

.modal-content {
  text-align: left;
}

.modal-name,
.modal-course {
  font-weight: bold;
}

.modal-time,
.modal-grade {
  font-size: 15px;
}

.skill-div-container:hover .skill-div {
  transform: scale(1.1); /* Increase the size on hover */
}
