#logo{
    position:absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    animation: fill 0.5s ease forwards 3s;
}

#logo path:nth-child(1){
    stroke-dasharray: 668;
    stroke-dashoffset: 668;
    animation: line-anim 2s ease forwards;
}
#logo path:nth-child(2){
    stroke-dasharray: 502;
    stroke-dashoffset: 502;
    animation: line-anim 2s ease forwards 0.3s;
}
#logo path:nth-child(3){
    stroke-dasharray: 872;
    stroke-dashoffset: 872;
    animation: line-anim 2s ease forwards 0.6s;
}
#logo path:nth-child(4){
    stroke-dasharray: 668;
    stroke-dashoffset: 668;
    animation: line-anim 2s ease forwards 0.9s;
}
#logo path:nth-child(5){
    stroke-dasharray: 872;
    stroke-dashoffset: 872;
    animation: line-anim 2s ease forwards 1.2s;
}

@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}
@keyframes fill{
    from{
        fill:transparent;
    }
    to{
        fill : #393E46;
    }
}