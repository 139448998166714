@keyframes slideup {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .slideup {
    animation: slideup 1s ease-in-out;
  }